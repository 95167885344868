import React from "react";
import { Link, graphql } from "gatsby";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ReactMarkdown from "react-markdown";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import WeddingMap from "../components/weddingmap";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import "./things-to-do.scss";

const ThingsToDoPage = ({ data }) => {

  let pois = {
    sanFrancisco: [],
    alongTheWay: [],
    mendocino: []
  };

	let getPoiLayout = (poi) => {
	    switch(poi.layout){
	    case 'left':
	    default:
	        return (
	            <Row className="thing-to-do">
	              <Col className="col-0 col-md-1"/>
	              <Col className="col-12 col-md-5">
	                <hr />
	                <p>
	                  <b>{poi.name}</b>
	                </p>
	                <ReactMarkdown source={poi.description} />
	              </Col>
	              <Col className="col-12 col-md-5 photo-container">
	                <img src={poi.photo} />
	              </Col>
	            </Row>
	        );
	        break;

	    case 'right':
	        return (
	            <Row className="thing-to-do">
	              <Col className="col-md-1" />
	              <Col className="col-12 col-md-5 photo-container">
	                <img src={poi.photo} />
	              </Col>
	              <Col className="col-12 col-md-5">
	                <hr />
	                <p>
	                  <b>{poi.name}</b>
	                </p>
	                <ReactMarkdown source={poi.description} />
	              </Col>
	            </Row>
	        );
	        break;
	    }
	};

	data.file.childPagesYaml.pois.map(poi => {

	  switch (poi.section) {
	    case "San Francisco":
	      pois.sanFrancisco.push(getPoiLayout(poi));
	      break;
	    case "Along the Way":
	      pois.alongTheWay.push(getPoiLayout(poi));
	      break;
	    case "Mendocino":
	      pois.mendocino.push(getPoiLayout(poi));
	      break;
	  }
	});

  return (
    <Layout page="things-to-do">
      <SEO title="Things To Do" />

      <PageHeader title={data.file.childPagesYaml.title} />

      <Row className="page-content section-header">
        <Col sm/>
        <Col className="col-12 col-md-10">
          <p>
            <h3>San Francisco</h3>
          </p>
          <p>{data.file.childPagesYaml.sanFrancisco}</p>
        </Col>
        <Col sm/>
      </Row>

      <Row className="page-content map-content">
      	<Col className="col-1" />
      	<Col className="col-12 col-md-10" >
      		<WeddingMap center={[-122.7792731, 38.7047379]} zoom={10} mapid={"sf"}/>
      	</Col>
      	<Col className="col-1" />
      </Row>

      <Row className="page-content">
        <Col className="col-12">
          {pois.sanFrancisco}
        </Col>
      </Row>

      <PageDivider />

      <Row className="page-content section-header">
        <Col sm/>
        <Col className="col-12 col-md-10">
          <p>
            <h3>Along the Way</h3>
          </p>
          <p>{data.file.childPagesYaml.alongTheWay}</p>
        </Col>
        <Col sm/>
      </Row>

      <Row className="page-content map-content">
      	<Col className="col-1" />
      	<Col className="col-12 col-md-10" >
      		<WeddingMap center={[-123.5374, 39.1511]} zoom={10} mapid={"alongtheway"}/>
      	</Col>
      	<Col className="col-1" />
      </Row>

      <Row className="page-content">
        <Col className="col-12">
          {pois.alongTheWay}
        </Col>
      </Row>

      <PageDivider />

      <Row className="page-content section-header">
        <Col sm/>
        <Col className="col-12 col-md-10">
          <p>
            <h3>Mendocino</h3>
          </p>
          <p>{data.file.childPagesYaml.mendocino}</p>
        </Col>
        <Col sm/>
      </Row>

			<Row className="page-content map-content">
      	<Col className="col-1" />
      	<Col className="col-12 col-md-10" >
      		<WeddingMap center={[-123.7218, 39.5652]} zoom={10} mapid={"mendocino"}/>
      	</Col>
      	<Col className="col-1" />
      </Row>

      <Row className="page-content">
        <Col className="col-12">
          {pois.mendocino}
        </Col>
      </Row>

      <PageFooter />

    </Layout>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "pages/things-to-do.yml" }) {
      childPagesYaml {
        title
        description
        sanFrancisco
        alongTheWay
        mendocino
        pois {
          name
          visible
          layout
          section
          description
          link
          section
          photo
          location
        }
      }
    }
  }
`;

export default ThingsToDoPage;
